<template>
  <div class="logo-container">
    <img src="/images/logo2.png" alt="СИНДИКАТ логотип" class="logo">
  </div>
  <IncomeCalculator />
  <footer class="footer">
    <img src="/images/icon.png" alt="Иконка СИНДИКАТ" class="icon">
  </footer>
</template>

<script>
import IncomeCalculator from './components/IncomeCalculator.vue'

export default {
  name: 'App',
  components: {
    IncomeCalculator
  }
}
</script>

<style>
.logo-container {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  max-width: 100%;
  height: auto;
  max-height: 5000px;
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.icon {
  max-width: 30%;
  max-height: 30%;
}
</style>