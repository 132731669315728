<template>
  <div>
    <label class="block mb-2">{{ label }}</label>
    <select v-model="selectedValue" class="custom-select" :aria-label="label">
      <option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'CustomSelect',
  props: {
    label: String,
    modelValue: String,
    options: Array
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selectedValue = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    return {
      selectedValue
    }
  }
}
</script>

<style scoped>
.custom-select {
  background-color: var(--bg-color);
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  color: var(--text-color);
}
.custom-select option {
  font-family: 'Rockwell Nova', 'IBM Plex Serif', 'Georgia', 'Times New Roman', 'Times', serif;
}
</style>