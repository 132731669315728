<template>
    <div id="app" class="max-w-4xl mx-auto p-4 sm:p-8">
      <h1 class="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6">Калькулятор дохода</h1>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4 sm:mb-6">
        <CustomSelect 
          label="Выбери регион" 
          v-model="selectedRegion" 
          :options="regions"
        />
        <CustomSelect 
          label="Выбери вещество" 
          v-model="selectedShippingType" 
          :options="shippingTypes" 
        />
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mb-4 sm:mb-6">
        <div :class="['custom-card', { 'active': selectedType === 'city_zone' }, 'card-select']" @click="selectType('city_zone')">
          <h2 class="font-bold mb-2">Магнит, тайник</h2>
          <p class="text-sm">Эти клады создаются в городской зоне. Магнит цепляют на металлические конструкции, а тайник прячут в укромное место.</p>
        </div>
        <div :class="['custom-card', { 'active': selectedType === 'suburb_zone'}, 'card-select']" @click="selectType('suburb_zone')">
          <h2 class="font-bold mb-2">Прикоп</h2>
          <p class="text-sm">Эти клады создаются в пригородной зоне. Прикоп закапывают в землю в немноголюдных местах, например, в парках или лесах.</p>
        </div>
      </div>
      <div class="mb-4 sm:mb-6">
        <label class="block mb-2">Сколько дней в неделю планируешь работать</label>
        <div class="flex items-center">
          <input type="range" min="1" max="7" v-model="daysPerWeek" class="custom-slider" tabindex=0 aria-label="Количество дней в неделю">
          <span class="ml-4">{{ daysPerWeek }}</span>
        </div>
      </div>
      <div class="mb-4 sm:mb-6">
        <label class="block mb-2">Количество кладов в день</label>
        <div class="flex items-center">
          <input type="range" min="1" max="100" v-model="cachesPerDay" class="custom-slider" tabindex=0 aria-label="Количество кладов в день">
          <span class="ml-4">{{ cachesPerDay }}</span>
        </div>
      </div>
      <div class="flex items-center mb-4 sm:mb-6">
        <input type="checkbox" id="weight" v-model="isSelfPacking" class="custom-checkbox mr-2">
        <label for="weight" class="flex items-center">
          <span class="ml-2">Сам займусь фасовкой</span>
        </label>
      </div>
      <div class="custom-card income-card mb-4 sm:mb-6">
        <h2 class="text-xl sm:text-2xl font-bold mb-4">Возможный доход</h2>
        <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
          <div class="mb-2 sm:mb-0 flex flex-wrap items-baseline">
              <span class="text-4xl md:text-6xl font-bold">{{ formatNumber(monthlyIncome) }} ₽</span>
              <span class="text-base md:text-md ml-2 whitespace-nowrap">за месяц</span>
          </div>
          <div class="flex flex-wrap items-baseline">
              <span class="text-4xl md:text-6xl font-bold">{{ formatNumber(weeklyIncome) }} ₽</span>
              <span class="text-base md:text-md ml-2 whitespace-nowrap">за неделю</span>
          </div>
        </div>
        <p class="mb-4">или выбери желаемый доход</p>
        <div class="income-options-container">
          <button 
            v-for="income in incomeOptions" 
            :key="income" 
            @click="selectIncome(income)" 
            :class="['income-option', { 'active': selectedIncome === income }]">
            {{ income }} ₽
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, computed, watch } from 'vue'
  import CustomSelect from './CustomSelect.vue'
  
  export default {
    name: 'IncomeCalculator',
    components: {
      CustomSelect
    },
    methods: {
      formatNumber(number) {
        return new Intl.NumberFormat('ru-RU').format(number);
      }
    },
    setup() {
      const selectedRegion = ref('moscow')
      const selectedShippingType = ref('cannabis')
      const selectedType = ref('city_zone')
      const daysPerWeek = ref(5)
      const cachesPerDay = ref(35)
      const isSelfPacking = ref(true)
      const selectedIncome = ref(null)
      const incomeOptions = ref(['100 000', '250 000', '400 000', '550 000', '700 000', '850 000'])
      const isAdjustingSliders = ref(false)
  
      const regions = [
      { value: 'moscow', label: 'Москва' },
        { value: 'moscow_region', label: 'Московская область' },
        { value: 'saint_petersburg', label: 'Санкт-Петербург' },
        { value: 'altai_krai', label: 'Алтайский край' },
        { value: 'amur_oblast', label: 'Амурская область' },
        { value: 'arkhangelsk_oblast', label: 'Архангельская область' },
        { value: 'astrakhan_oblast', label: 'Астраханская область' },
        { value: 'belgorod_oblast', label: 'Белгородская область' },
        { value: 'bryansk_oblast', label: 'Брянская область' },
        { value: 'vladimir_oblast', label: 'Владимирская область' },
        { value: 'volgograd_oblast', label: 'Волгоградская область' },
        { value: 'vologda_oblast', label: 'Вологодская область' },
        { value: 'voronezh_oblast', label: 'Воронежская область' },
        { value: 'jewish_autonomous_oblast', label: 'Еврейская автономная область' },
        { value: 'zabaykalsky_krai', label: 'Забайкальский край' },
        { value: 'ivanovo_oblast', label: 'Ивановская область' },
        { value: 'irkutsk_oblast', label: 'Иркутская область' },
        { value: 'kabardino_balkar_republic', label: 'Кабардино-Балкарская Республика' },
        { value: 'kaliningrad_oblast', label: 'Калининградская область' },
        { value: 'kaluga_oblast', label: 'Калужская область' },
        { value: 'kamchatka_krai', label: 'Камчатский край' },
        { value: 'karachay_cherkess_republic', label: 'Карачаево-Черкесская Республика' },
        { value: 'kemerovo_oblast', label: 'Кемеровская область' },
        { value: 'kirov_oblast', label: 'Кировская область' },
        { value: 'kostroma_oblast', label: 'Костромская область' },
        { value: 'krasnodar_krai', label: 'Краснодарский край' },
        { value: 'krasnoyarsk_krai', label: 'Красноярский край' },
        { value: 'kurgan_oblast', label: 'Курганская область' },
        { value: 'kursk_oblast', label: 'Курская область' },
        { value: 'leningrad_oblast', label: 'Ленинградская область' },
        { value: 'lipetsk_oblast', label: 'Липецкая область' },
        { value: 'magadan_oblast', label: 'Магаданская область' },
        { value: 'murmansk_oblast', label: 'Мурманская область' },
        { value: 'nenets_autonomous_okrug', label: 'Ненецкий автономный округ' },
        { value: 'nizhny_novgorod_oblast', label: 'Нижегородская область' },
        { value: 'novgorod_oblast', label: 'Новгородская область' },
        { value: 'novosibirsk_oblast', label: 'Новосибирская область' },
        { value: 'omsk_oblast', label: 'Омская область' },
        { value: 'orenburg_oblast', label: 'Оренбургская область' },
        { value: 'orlov_oblast', label: 'Орловская область' },
        { value: 'penza_oblast', label: 'Пензенская область' },
        { value: 'perm_krai', label: 'Пермский край' },
        { value: 'primorsky_krai', label: 'Приморский край' },
        { value: 'pskov_oblast', label: 'Псковская область' },
        { value: 'adygea_republic', label: 'Республика Адыгея' },
        { value: 'altai_republic', label: 'Республика Алтай' },
        { value: 'bashkortostan_republic', label: 'Республика Башкортостан' },
        { value: 'buriatia_republic', label: 'Республика Бурятия' },
        { value: 'dagestan_republic', label: 'Республика Дагестан' },
        { value: 'ingushetia_republic', label: 'Республика Ингушетия' },
        { value: 'kalmykia_republic', label: 'Республика Калмыкия' },
        { value: 'karelia_republic', label: 'Республика Карелия' },
        { value: 'komi_republic', label: 'Республика Коми' },
        { value: 'crimea_republic', label: 'Республика Крым' },
        { value: 'mari_el_republic', label: 'Республика Марий Эл' },
        { value: 'mordovia_republic', label: 'Республика Мордовия' },
        { value: 'saha_republic', label: 'Республика Саха (Якутия)' },
        { value: 'north_ossetia_republic', label: 'Республика Северная Осетия — Алания' },
        { value: 'tatarstan_republic', label: 'Республика Татарстан' },
        { value: 'tuva_republic', label: 'Республика Тыва' },
        { value: 'khakassia_republic', label: 'Республика Хакасия' },
        { value: 'rostov_oblast', label: 'Ростовская область' },
        { value: 'ryazan_oblast', label: 'Рязанская область' },
        { value: 'samara_oblast', label: 'Самарская область' },
        { value: 'saratov_oblast', label: 'Саратовская область' },
        { value: 'sakhalin_oblast', label: 'Сахалинская область' },
        { value: 'sverdlovsk_oblast', label: 'Свердловская область' },
        { value: 'sevastopol', label: 'Севастополь' },
        { value: 'smolensk_oblast', label: 'Смоленская область' },
        { value: 'stavropol_krai', label: 'Ставропольский край' },
        { value: 'tambov_oblast', label: 'Тамбовская область' },
        { value: 'tver_oblast', label: 'Тверская область' },
        { value: 'tomsk_oblast', label: 'Томская область' },
        { value: 'tula_oblast', label: 'Тульская область' },
        { value: 'tyumen_oblast', label: 'Тюменская область' },
        { value: 'udmurt_republic', label: 'Удмуртская Республика' },
        { value: 'uliyanovsk_oblast', label: 'Ульяновская область' },
        { value: 'khabarovsk_krai', label: 'Хабаровский край' },
        { value: 'khanty_mansi_autonomous_okrug', label: 'Ханты-Мансийский автономный округ — Югра' },
        { value: 'chelyabinsk_oblast', label: 'Челябинская область' },
        { value: 'chechen_republic', label: 'Чеченская Республика' },
        { value: 'chuvash_republic', label: 'Чувашская Республика' },
        { value: 'chukchi_autonomous_okrug', label: 'Чукотский автономный округ' },
        { value: 'yamalo_nenets_autonomous_okrug', label: 'Ямало-Ненецкий автономный округ' },
        { value: 'yaroslavl_oblast', label: 'Ярославская область' }
      ]
  
      const shippingTypes = [
        { value: 'cannabis', label: 'Гашиш' },
        { value: 'mephedrone', label: 'Мефедрон' },
        { value: 'α-PVP', label: 'Альфа-ПВП' },
      ]
  
      const basePrice = computed(() => {
        let price = 900
        if (selectedRegion.value === 'arkhangelsk_oblast') {
          if (selectedShippingType.value === 'cannabis') price = 900
          else if (selectedShippingType.value === 'mephedrone') price = 900
          else if (selectedShippingType.value === 'α-PVP') price = 900
        }
        if (isSelfPacking.value) price += 100
        if (selectedType.value === 'city_zone' && isSelfPacking.value) price += 100
        
        let cachesPerMonth = cachesPerDay.value * daysPerWeek.value * 4
        if (cachesPerMonth > 1500) price += 200
        else if (cachesPerMonth > 1000) price += 175
        else if (cachesPerMonth > 750) price += 150
        else if (cachesPerMonth > 500) price += 125
        else if (cachesPerMonth > 250) price += 100
        else if (cachesPerMonth > 150) price += 75
        else if (cachesPerMonth > 100) price += 50
  
        return price
      })
  
      const monthlyIncome = computed(() => {
        return (cachesPerDay.value * daysPerWeek.value * 4 * basePrice.value)
      })
  
      const weeklyIncome = computed(() => {
        return (cachesPerDay.value * daysPerWeek.value * basePrice.value)
      })
  
      const selectType = (type) => {
        selectedType.value = type
      }
  
      const selectIncome = (income) => {
        selectedIncome.value = income
        isAdjustingSliders.value = true
        adjustSlidersForIncome(income)
        setTimeout(() => {
          isAdjustingSliders.value = false
        }, 0)
      }
  
      const adjustSlidersForIncome = (income) => {
        const incomeValue = parseInt(income.replace(/\s/g, ''))
        isSelfPacking.value = true
        selectedType.value = 'city_zone'
        switch (incomeValue) {
          case 100000:
            daysPerWeek.value = 2
            cachesPerDay.value = 12
            break
          case 250000:
            daysPerWeek.value = 3
            cachesPerDay.value = 18
            break
          case 400000:
            daysPerWeek.value = 3
            cachesPerDay.value = 28
            break
          case 550000:
            daysPerWeek.value = 4
            cachesPerDay.value = 29
            break
          case 700000:
            daysPerWeek.value = 4
            cachesPerDay.value = 36
            break
          case 850000:
            daysPerWeek.value = 5
            cachesPerDay.value = 35
            break
        }
      }
  
      watch([daysPerWeek, cachesPerDay], () => {
        if (!isAdjustingSliders.value) {
          selectedIncome.value = null
        }
      })
  
      return {
        selectedRegion,
        selectedShippingType,
        selectedType,
        daysPerWeek,
        cachesPerDay,
        isSelfPacking,
        selectedIncome,
        incomeOptions,
        basePrice,
        monthlyIncome,
        weeklyIncome,
        selectType,
        selectIncome,
        regions,
        shippingTypes
      }
    }
  }
  </script>
  
  <style scoped>

  .custom-select option {
    font-family: 'Rockwell Nova', 'IBM Plex Serif', 'Georgia', 'Times New Roman', 'Times', serif;
  }
  
  @media (max-width: 960px) {
    .income-option {
      flex-basis: calc(33.333% - 10px);
    }
  }
  
  @media (max-width: 768px) {
    .custom-card.income-card {
      margin-top: 2rem;
      margin-bottom: 2rem;
      padding: 1.5rem;
    }
    .income-option {
      flex-basis: calc(50% - 10px);
    }
  }
  
  @media (max-width: 320px) {
    .income-option {
      flex-basis: 100%;
    }
  }
  </style>